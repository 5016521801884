import { z } from "zod"
import { Ref } from "@energuide/shared-common"
import { SubscriptionSchema } from "../subscription"

//TODO: Bad design choice that we got ProductId and ProductIdentifier which are kinda the same but ProductIdentifier is more specific by including the project and variant -> refactor
enum ProductId {
    ConsumptionCertificate = 1, // Verbrauchsausweis
    DemandCertificate = 2, //Bedarfsausweis
    ProSubscription = 3,
    S_Subscription = 4,
    M_Subscription = 5,
    L_Subscription = 6,

    Coupon_ConsumptionCertificate = 7,
    Coupon_DemandCertificate = 8
}

const ProductIdSchema = z.preprocess(
    (value) => typeof value === "string" ? parseInt(value, 10) : value,
    z.nativeEnum(ProductId)
);

enum ProductType {
    Subscription = "subscription",
    Certificate = "certificate",
    Coupon = "coupon",
}

/**
 * @description Product schema for the frontend
 * @property {number} price - The product price in cents, can be null if the product is a subscription
 */
const ProductSchema = z.object({
    id: z.number(),
    name: z.string(),
    productType: z.enum([ProductType.Certificate, ProductType.Subscription, ProductType.Coupon]),
    subscriptionRef: Ref(SubscriptionSchema),
    stripeProductId: z.string().nullable(),
    price: z.number().positive().nullable(),
})

type IProduct = z.infer<typeof ProductSchema>

export { ProductSchema, ProductId, ProductIdSchema, ProductType }
export type { IProduct }
