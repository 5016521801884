export * from "./loading-dots"
export * from "./link-button"
export * from "./button"

export type Variant = "primary" | "secondary" | "tertiary" | "warning" | "success" | "error" | "freeform"
export const Radius = {
    small: "rounded-lg",
    big: "rounded-3xl",
    full: "rounded-full",
}
export const variants: Record<Variant, string> = {
    primary:
        "bg-primary text-primary-content transition duration-150 ease-in-out hover:bg-primary/80 disabled:bg-primary-disabled disabled:text-primary-content-disabled data-[disabled]:bg-primary-disabled data-[disabled]:text-primary-content-disabled data-[state=on]:bg-primary data-[state=on]:text-primary-content flex justify-center gap-2 place-items-center px-4 min-h-10 font-button text-primary-content",
    secondary:
        "bg-secondary text-secondary-content transition duration-150 ease-in-out hover:bg-secondary/80 disabled:bg-secondary-disabled disabled:text-secondary-content-disabled data-[disabled]:bg-secondary-disabled data-[disabled]:text-secondary-content-disabled flex justify-center gap-2 place-items-center px-4 min-h-10 font-button text-secondary-content",
    tertiary: "flex justify-center gap-2 place-items-center font-button px-4 min-h-10 text-label text-primary-accent",
    warning:
        "text-warning-content border border-warning bg-warning/10 transition duration-150 ease-in-out hover:bg-warning/20 disabled:text-warning-content/50 disabled:bg-warning/50 data-[disabled]:text-warning-content/50 data-[disabled]:bg-warning/50 justify-center flex gap-2 place-items-center px-4 min-h-10 font-button text-warning",
    error: "text-error-content border border-error bg-error/10 transition duration-150 ease-in-out hover:bg-error/20 disabled:text-error-content/50 disabled:bg-error/50 data-[disabled]:text-error-content/50 data-[disabled]:bg-error/50 justify-center flex gap-2 place-items-center px-4 min-h-10 font-button text-error",
    success:
        "text-success-content border border-success bg-success/10 transition duration-150 ease-in-out hover:bg-success/20 disabled:text-success-content/50 disabled:bg-success/50 data-[disabled]:text-success-content/50 data-[disabled]:bg-success/50 justify-center flex gap-2 place-items-center px-4 min-h-10 font-button text-success",
    freeform: "",
}
