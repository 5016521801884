import { Button } from "@components/atoms/buttons"
import { useEffect, useState } from "react"

const SwitchThemeButton = () => {
    const [isDark, setIsDark] = useState(false)

    useEffect(() => {
        // Check for saved preference first
        const savedTheme = localStorage.getItem("theme")
        if (savedTheme) {
            setIsDark(savedTheme === "dark")
            document.documentElement.classList.add(savedTheme)
        } else {
            // If no saved preference, use system preference
            const systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches
            setIsDark(systemDark)
            document.documentElement.classList.add(systemDark ? "dark" : "light")

            // Listen for system theme changes when no user preference is set
            const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
            const listener = (e: MediaQueryListEvent) => {
                if (!localStorage.getItem("theme")) {
                    setIsDark(e.matches)
                    document.documentElement.classList.remove("dark", "light")
                    document.documentElement.classList.add(e.matches ? "dark" : "light")
                }
            }
            mediaQuery.addEventListener("change", listener)
            return () => mediaQuery.removeEventListener("change", listener)
        }
    }, [])

    const toggleTheme = () => {
        const newIsDark = !isDark
        setIsDark(newIsDark)

        // Remove both classes first
        document.documentElement.classList.remove("dark", "light")
        // Add new class
        document.documentElement.classList.add(newIsDark ? "dark" : "light")
        // Save preference
        localStorage.setItem("theme", newIsDark ? "dark" : "light")
    }

    return (
        <Button
            variant="freeform"
            onClick={toggleTheme}
            data-testid="switch-theme-button"
            title={`Switch to ${isDark ? "light" : "dark"} mode`}
            className="px-4"
        >
            <i className={`${isDark ? "ri-sun-fill" : "ri-moon-fill"} text-[1.2rem]`}></i>
        </Button>
    )
}

export default SwitchThemeButton
