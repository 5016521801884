import { Spinner } from "@components/atoms/buttons/spinner"
import * as Avatar from "@radix-ui/react-avatar"
import { cls } from "@utils"
import React from "react"

type Variant = "big" | "menu" | "chat" | "mini"

type IUserAvatar = Avatar.AvatarProps & {
    src: string
    alt: string
    initials: string
    variant: Variant
}

export function UserAvatar(props: IUserAvatar) {
    const { className, src, alt, initials, variant, onClick, ...avatarProps } = props
    const [loading, setLoading] = React.useState<boolean>(false)

    const variants: Record<Variant, string> = {
        big: "h-36 w-36 text-heading2",
        menu: "h-16 w-16 text-body1",
        chat: "h-12 w-12 text-body2",
        mini: "h-8 w-8 text-small",
    }

    return (
        <Avatar.Root
            {...avatarProps}
            className={cls("relative block overflow-hidden rounded-full text-body1", variants[variant], className)}
        >
            <Avatar.Image
                className="absolute bottom-0 left-0 right-0 top-0 h-full max-h-none w-full object-cover"
                src={src}
                alt={alt}
                onLoadingStatusChange={(status) => {
                    setLoading(status === "loading")
                }}
            />
            <Avatar.Fallback className="absolute inset-0 grid place-items-center rounded-full bg-primary-accent">
                {loading ? <Spinner /> : initials}
            </Avatar.Fallback>
            {onClick ? (
                <div
                    className="bg-basic/90 absolute inset-[-1px] grid place-items-center rounded-full opacity-0 transition-opacity hover:opacity-100"
                    onClick={onClick}
                >
                    <i className={"ri-edit-fill text-basic-content text-[1.5rem] leading-none"}></i>
                </div>
            ) : null}
        </Avatar.Root>
    )
}
