import { z } from "zod"
import { ISO8601DateSchema, Ref } from "@energuide/shared-common"
import { SubscriptionInterval, SubscriptionSchema } from "../subscription"

enum UserSubscriptionStatus {
    Active = "active",
    Canceled = "canceled",
    ActiveCanceled = "active_canceled",
    Awaiting = "awaiting",
    RenewalFailed = "renewal_failed",
}

const userSubscriptionStatusSchema = z.nativeEnum(UserSubscriptionStatus)

const UserSubscriptionSchema = z.object({
    id: z.number(),
    userRef: Ref(),
    subscriptionRef: Ref(SubscriptionSchema),
    orderRef: Ref(),
    startDate: ISO8601DateSchema,
    endDate: ISO8601DateSchema.nullable(),
    status: userSubscriptionStatusSchema,
    interval: z.nativeEnum(SubscriptionInterval),
    inTrial: z.boolean().optional().nullable(),
    currentPeriodEnd: ISO8601DateSchema.nullable(),
})

type IUserSubscription = z.infer<typeof UserSubscriptionSchema>

export { UserSubscriptionSchema, userSubscriptionStatusSchema, UserSubscriptionStatus }
export type { IUserSubscription }
