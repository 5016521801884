export type IHeading = React.PropsWithChildren<{
    level: "h1" | "h2" | "h3" | "h4"
    className?: string
}>

export function Heading(props: IHeading) {
    const { children, className, level } = props

    switch (level) {
        case "h1":
            return (
                <h1 className={`break-words font-heading text-heading1 text-black-white ${className}`}>{children}</h1>
            )
        case "h2":
            return (
                <h2 className={`break-words font-heading text-heading2 text-black-white ${className}`}>{children}</h2>
            )
        case "h3":
            return (
                <h3 className={`break-words font-heading text-heading3 text-black-white ${className}`}>{children}</h3>
            )
        case "h4":
            return (
                <h3 className={`break-words font-heading text-heading4 text-black-white ${className}`}>{children}</h3>
            )
    }
}
