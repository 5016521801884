import { ErrorFallback } from "@components/atoms/error-fallback"
import { StyledToaster } from "@components/atoms/toaster"
import { InitialProjectLoader } from "@components/molecules/initial-project-loader"
import { AppleRedirect, FacebookRedirect, GoogleRedirect, UserRedirect } from "@components/organisms/oauth-redirect"
import config from "@config"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useAppState } from "@hooks/useState"
import { AuthLayout } from "@layouts/auth"
import { AuthenticatedLayout } from "@layouts/authenticated"
import { LoadingProvider } from "@layouts/loading-provider"
import { AccountTypeSelection } from "@pages/auth/account-type-selection"
import { Confirmation } from "@pages/auth/confirmation"
import { ForgotPassword } from "@pages/auth/forgot-password"
import { ForgotConfirmation } from "@pages/auth/forgotConfirmation"
import { InvalidToken } from "@pages/auth/invalid-token"
import { Login } from "@pages/auth/login"
import { LoginSelection } from "@pages/auth/login-selection"
import { Register } from "@pages/auth/register"
import { RegisterAdditions } from "@pages/auth/register-additions"
import { RegisterSelection } from "@pages/auth/register-selection"
import { RegisterTutorial } from "@pages/auth/register-tutorial"
import { ResetPassword } from "@pages/auth/reset-password"
import { DataOverview } from "@pages/data"
import { Intro } from "@pages/intro"
import { StripeCheckout } from "@pages/stripe/checkout"
import { StripeError } from "@pages/stripe/error"
import { StripeSuccess } from "@pages/stripe/success"
import { ErrorBoundary } from "@sentry/react"
import { AnimatePresence, motion } from "framer-motion"
import React, { ReactNode, Suspense } from "react"
import { Helmet } from "react-helmet-async"
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom"

const Account = React.lazy(() => import("@pages/account"))
const BuildingPass = React.lazy(() => import("@pages/building-pass"))
const Chat = React.lazy(() => import("@pages/chat"))
const DataProtection = React.lazy(() => import("@pages/data-protection"))
const EnergyCertificates = React.lazy(() => import("@pages/energy-certificates"))
const LazyLicense = React.lazy(() => import("@pages/license"))
const ProjectDebug = React.lazy(() => import("@pages/project-debug"))
const ProjectOverview = React.lazy(() => import("@pages/project-overview"))
const ProjectSettings = React.lazy(() => import("@pages/project-settings"))
const ReviewConfirmation = React.lazy(() => import("@pages/reviews/review-confirmation"))
const ReviewDetail = React.lazy(() => import("@pages/reviews/review-detail"))
const ReviewList = React.lazy(() => import("@pages/reviews/review-list"))
const Terms = React.lazy(() => import("@pages/terms"))

function MotionPage(props: React.PropsWithChildren<object>) {
    return (
        <motion.div
            initial={{ opacity: 0, transform: "translateX(100%)" }}
            animate={{ opacity: 1, transform: "translateX(0%)" }}
            exit={{ opacity: 0, transform: "translateX(-100%)" }}
            className="absolute inset-0"
        >
            {props.children}
        </motion.div>
    )
}

function Suspended(props: { children?: ReactNode }) {
    return <Suspense fallback={null}>{props.children}</Suspense>
}

export function App() {
    const isHydrated = useAppState((state) => state.isHydrated)
    const location = useLocation()
    const hasSeenIntro = useAppState((state) => state.hasSeenIntro)
    const locationArr = location.pathname?.split("/") ?? []
    const isAuth = useAuthHeader()
    const user = useAppState((state) => state.user)
    const navigate = useNavigate()

    React.useEffect(() => {
        const isCurrentPath = (targetPath: string) => location.pathname === targetPath

        if (!isHydrated) {
            return
        }

        if (isCurrentPath("/auth/account-type-selection") || isCurrentPath("/auth/register-additions")) {
            return
        }

        if (!hasSeenIntro && !isCurrentPath("/auth/intro") && window.location.host !== "oauth.enerithm.com") {
            navigate("/auth/intro")
            return
        }

        if (!isAuth) {
            return
        }

        if (user && (user.accountTypeRef === null || user.accountTypeRef === undefined)) {
            navigate("/auth/account-type-selection")
            return
        }

        if (user && !user.displayName) {
            navigate("/auth/register-additions")
            return
        }
    }, [isAuth, location, hasSeenIntro, user, isHydrated, navigate])

    if (!isHydrated) {
        return null
    }

    const isStaging = config.VITE_APP_ENV === "staging"

    return (
        <ErrorBoundary fallback={<ErrorFallback />}>
            <StyledToaster />
            <LoadingProvider />
            <Helmet>{isStaging && <meta name="robots" content="noindex,nofollow" />}</Helmet>
            <Routes>
                <Route
                    path="/*"
                    errorElement={<ErrorFallback />}
                    element={
                        <>
                            <AnimatePresence initial={false}>
                                <Routes location={location} key={locationArr[1]}>
                                    <Route
                                        path="/auth/*"
                                        element={
                                            <MotionPage>
                                                <AuthLayout>
                                                    <Routes location={location} key={locationArr[2]}>
                                                        <Route path="/intro" element={<Intro />} />
                                                        <Route path="/login-selection" element={<LoginSelection />} />
                                                        <Route path="/login" element={<Login />} />
                                                        <Route
                                                            path="/register-selection"
                                                            element={<RegisterSelection />}
                                                        />
                                                        <Route path="/register" element={<Register />} />
                                                        <Route
                                                            path="/register-additions"
                                                            element={<RegisterAdditions />}
                                                        />
                                                        <Route
                                                            path="/account-type-selection"
                                                            element={<AccountTypeSelection />}
                                                        />
                                                        <Route
                                                            path="/register-tutorial"
                                                            element={<RegisterTutorial />}
                                                        />
                                                        <Route path="/forgot-password" element={<ForgotPassword />} />
                                                        <Route
                                                            path="/forgot-confirmation"
                                                            element={<ForgotConfirmation />}
                                                        />
                                                        <Route path="/reset-password" element={<ResetPassword />} />
                                                        <Route path="/confirmation" element={<Confirmation />} />
                                                        <Route path="/invalid-token" element={<InvalidToken />} />
                                                        <Route path="*" element={<Navigate to={"/login"} />} />
                                                    </Routes>
                                                </AuthLayout>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/authenticated/*"
                                        errorElement={<ErrorFallback />}
                                        element={
                                            <MotionPage>
                                                <AuthenticatedLayout>
                                                    <Routes location={location} key={locationArr[2]}>
                                                        <Route
                                                            path="/chat/:projectId"
                                                            element={
                                                                <Suspended>
                                                                    <Chat />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route path="/chat" element={<InitialProjectLoader />} />
                                                        <Route
                                                            path="/account"
                                                            element={
                                                                <Suspended>
                                                                    <Account />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/project-overview/:projectId"
                                                            element={
                                                                <Suspended>
                                                                    <ProjectOverview />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/energy-certificates/:projectId"
                                                            element={
                                                                <Suspended>
                                                                    <EnergyCertificates />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/building-pass/:projectId"
                                                            element={
                                                                <Suspended>
                                                                    <BuildingPass />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/project-settings/:projectId"
                                                            element={
                                                                <Suspended>
                                                                    <ProjectSettings />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/data-overview/:projectId"
                                                            element={
                                                                <Suspended>
                                                                    <DataOverview />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/data-protection/:projectId"
                                                            element={
                                                                <Suspended>
                                                                    <DataProtection />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route path="/terms/:projectId" element={<Terms />} />
                                                        <Route
                                                            path="/reviews"
                                                            element={
                                                                <Suspended>
                                                                    <ReviewList />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/review/:draftId"
                                                            element={
                                                                <Suspended>
                                                                    <ReviewDetail />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/review-confirmation/:draftId"
                                                            element={
                                                                <Suspended>
                                                                    <ReviewConfirmation />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="/project-debug"
                                                            element={
                                                                <Suspended>
                                                                    <ProjectDebug />
                                                                </Suspended>
                                                            }
                                                        />
                                                        <Route
                                                            path="*"
                                                            element={<Navigate to={"/authenticated/chat"} />}
                                                        />
                                                    </Routes>
                                                </AuthenticatedLayout>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/payment/*"
                                        element={
                                            <MotionPage>
                                                <Routes location={location} key={locationArr[2]}>
                                                    <Route path="/checkout" element={<StripeCheckout />} />
                                                    <Route path="/error" element={<StripeError />} />
                                                    <Route path="/success" element={<StripeSuccess />} />
                                                </Routes>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/connect/*"
                                        element={
                                            <MotionPage>
                                                <Routes location={location} key={locationArr[2]}>
                                                    <Route
                                                        path="/google/user-redirect"
                                                        element={<UserRedirect to="/connect/google/redirect" />}
                                                    />
                                                    <Route path="/google/redirect" element={<GoogleRedirect />} />

                                                    <Route
                                                        path="/facebook/user-redirect"
                                                        element={<UserRedirect to="/connect/facebook/redirect" />}
                                                    />
                                                    <Route path="/facebook/redirect" element={<FacebookRedirect />} />

                                                    <Route
                                                        path="/apple/user-redirect"
                                                        element={<UserRedirect to="/connect/apple/redirect" />}
                                                    />
                                                    <Route path="/apple/redirect" element={<AppleRedirect />} />
                                                </Routes>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/data-protection"
                                        element={
                                            <MotionPage>
                                                <AuthLayout>
                                                    <Suspended>
                                                        <DataProtection />
                                                    </Suspended>
                                                </AuthLayout>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/terms"
                                        element={
                                            <MotionPage>
                                                <AuthLayout>
                                                    <Suspended>
                                                        <Terms />
                                                    </Suspended>
                                                </AuthLayout>
                                            </MotionPage>
                                        }
                                    />
                                    <Route
                                        path="/licenses"
                                        element={
                                            <MotionPage>
                                                <AuthLayout>
                                                    <Suspended>
                                                        <LazyLicense />
                                                    </Suspended>
                                                </AuthLayout>
                                            </MotionPage>
                                        }
                                    />

                                    <Route path="*" element={<Navigate to={"/authenticated/chat"} />} />
                                </Routes>
                            </AnimatePresence>
                        </>
                    }
                ></Route>
            </Routes>
        </ErrorBoundary>
    )
}
