import { useMediaQuery } from "@hooks/useMediaQuery"
import { useMobile } from "@hooks/useMobile"
import { Toaster } from "sonner"
import { Spinner } from "./buttons/spinner"

export function StyledToaster() {
    const isSmallMobile = useMediaQuery("(max-width: 600px)")
    const isLargerMobile = useMobile()

    return (
        <Toaster
            position={isSmallMobile || isLargerMobile ? "top-center" : "bottom-right"}
            duration={3000}
            visibleToasts={1}
            style={
                isSmallMobile
                    ? {
                          right: "2rem",
                          left: "2rem",
                          top: `1rem`,
                          width: "calc(100% - 4rem)",
                      }
                    : {}
            }
            icons={{
                loading: <Spinner />,
            }}
            toastOptions={{
                unstyled: true,
                style: {
                    width: "100%",
                },
                classNames: {
                    error: "font-text w-full flex gap-2 items-center px-4 py-3 rounded-lg bg-warning text-white",
                    success: "font-text w-full flex gap-2 items-center px-4 py-3 rounded-lg bg-primary text-white",
                    info: "font-text w-full flex gap-2 items-center px-4 py-3 rounded-lg bg-primary text-white",
                    loading: "font-text w-full flex gap-2 items-center px-4 py-3 rounded-lg bg-primary text-white",
                },
            }}
        />
    )
}
