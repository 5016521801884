import { Button, LinkButton } from "./buttons"
import { Text } from "./typegraphy"

export function ErrorFallback() {
    return (
        <div className="grid h-dvh w-full place-content-center place-items-center gap-8 p-6">
            <div className="text-basic-content grid place-items-center text-center">
                <i className="ri-emotion-sad-line text-[4rem]"></i>
                <Text variant="body2">Ohje, das sollte nicht passieren.</Text>
                <Text variant="body2">Lade die App neu und schaue, ob das Problem weiterhin besteht.</Text>
            </div>
            <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2">
                <Button variant="primary" onClick={() => window.location.reload()}>
                    <i className="ri-refresh-line text-[1.5rem]"></i>
                    Neu laden
                </Button>
                <LinkButton href="mailto:info@enerithm.com" variant="secondary">
                    <i className="ri-mail-line text-[1.5rem]"></i>
                    Support
                </LinkButton>
            </div>
        </div>
    )
}
