import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { useCreateChat } from "@api/chat/useCreateChat"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useClearChat(projectId: number | null) {
    const authHeader = useAuthHeader()
    const createChatMutation = useCreateChat(projectId)

    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !projectId) {
                return null
            }

            const formData = new FormData()
            formData.append("projectId", projectId.toString())

            return handleFetch(() =>
                fetch(`${apiUrl}/chat/clear`, {
                    method: "post",
                    body: formData,
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Chatverlauf geleert")
            await queryClient.invalidateQueries({
                queryKey: ["chats", projectId],
            })
            createChatMutation.mutate({
                intent_type: "init_data_collection",
                message: "",
                files: [],
            })
        },
    })
}
