import { z } from "zod"
import { ACCOUNT_TYPES } from "./constants"

const AccountTypeIdSchema = z.union([z.literal(ACCOUNT_TYPES.Private.id), z.literal(ACCOUNT_TYPES.Enterprise.id)])

const AccountTypeValueSchema = z.enum([ACCOUNT_TYPES.Private.type, ACCOUNT_TYPES.Enterprise.type])

const AccountTypeSchema = z.object({
    id: AccountTypeIdSchema,
    type: AccountTypeValueSchema,
    pricesIncludeVAT: z.boolean(),
})

type AccountType = z.infer<typeof AccountTypeSchema>

export { AccountTypeIdSchema, AccountTypeValueSchema, AccountTypeSchema }
export type { AccountType }
