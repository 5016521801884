import { z } from "zod"
import { Ref } from "@energuide/shared-common"

enum EnergyCertificateType {
    Demand = "demand",
    Consumption = "consumption",
}
const EnergyCertificateTypeSchema = z.nativeEnum(EnergyCertificateType)
const EnergyCertificatTypeMap: Record<EnergyCertificateType, string> = {
    demand: "Bedarfsausweis",
    consumption: "Verbrauchsausweis",
}

enum EnergyCertificateVariant {
    Draft = "draft",
    Final = "final",
}
const EnergyCertificateVariantSchema = z.nativeEnum(EnergyCertificateVariant)

const EnergyCertificateComponentSchema = z.object({
    id: z.number(),
    error: z.string().nullable(),
    src: z.string().nullable(),
})

type IEnergyCertificateComponent = z.infer<typeof EnergyCertificateComponentSchema>

const EnergyCertificateCollectionSchema = z.object({
    id: z.number(),
    project: Ref(),
    taskId: z.string(),
    type: EnergyCertificateTypeSchema,
    druckappInput: z.string().nullable(),
    draft: EnergyCertificateComponentSchema.nullable().optional(),
    final: EnergyCertificateComponentSchema.nullable().optional(),
    lastSentChatId: z.number().optional(),
    comment: z.string().nullish(),
    userRef: Ref(z.string()),
    productRef: Ref(),
    orderItemRef: Ref(), //!OrderItemSchema, cycling dependency!
})

type IEnergyCertificateCollection = z.infer<typeof EnergyCertificateCollectionSchema>

const Counts = z.object({
    total: z.number(),
    answered: z.number(),
})

const ProgressResponse = z
    .object({
        consumption_certificate: Counts.default({ total: 0, answered: 0 }),
        demand_certificate: Counts.default({ total: 0, answered: 0 }),
    })
    .default({
        consumption_certificate: { total: 0, answered: 0 },
        demand_certificate: { total: 0, answered: 0 },
    })

type IProgressResponse = z.infer<typeof ProgressResponse>

export {
    EnergyCertificateCollectionSchema,
    EnergyCertificateComponentSchema,
    EnergyCertificateVariantSchema,
    EnergyCertificateType,
    EnergyCertificateTypeSchema,
    EnergyCertificatTypeMap,
    ProgressResponse,
    EnergyCertificateVariant,
}

export type { IEnergyCertificateCollection, IEnergyCertificateComponent, IProgressResponse }
