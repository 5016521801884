import React from "react"

export function useScroll<T extends HTMLElement>(
    scrollTo: "top" | "bottom",
    scrollOnAdd: boolean,
    scrollBehavior?: ScrollBehavior,
    filter?: (addedNodes: NodeList) => boolean
) {
    const ref = React.useRef<T | null>(null)

    const scroll = React.useCallback((scrollTo: "top" | "bottom", scrollBehavior?: ScrollBehavior) => {
        ref.current?.scrollTo({
            top: scrollTo === "top" ? 0 : ref.current.scrollHeight,
            behavior: scrollBehavior ?? "instant",
        })
    }, [])

    React.useEffect(() => {
        const scroller = ref.current

        if (!scroller) {
            return
        }

        const observer = new MutationObserver((mutations) => {
            if (!filter) {
                scroll(scrollTo, scrollBehavior)
            }

            for (const mutation of mutations) {
                if (filter?.(mutation.addedNodes)) {
                    scroll(scrollTo, scrollBehavior)
                }
            }
        })

        if (scrollOnAdd) {
            observer.observe(scroller, {
                childList: true,
                subtree: true,
            })
        }

        return () => {
            observer.disconnect()
        }
    }, [scrollBehavior, scrollOnAdd, scrollTo, filter, scroll])

    return {
        ref,
        manualScroll: scroll,
    }
}
