import { Button } from "@components/atoms/buttons"
import { GradientBackground } from "@components/atoms/gradient"
import { Heading } from "@components/atoms/typography"
import { useHeight } from "@hooks/useHeight"
import * as Collapsible from "@radix-ui/react-collapsible"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import React, { PropsWithChildren } from "react"

type ICollapsible = PropsWithChildren<{
    title: string
    defaultOpen?: boolean
}>

export function Reveal(props: ICollapsible) {
    const { title, defaultOpen, children } = props
    const [open, setOpen] = React.useState<boolean>(defaultOpen ?? false)
    const { height } = useHeight(open)

    return (
        <Collapsible.Root open={open} onOpenChange={setOpen}>
            <GradientBackground variant="dark" className="" radius="big">
                <Collapsible.Trigger asChild>
                    <Button variant="freeform" className="flex items-center justify-between p-4">
                        <Heading
                            level="h4"
                            className={cls("text-left", {
                                "!text-secondary": open,
                                "!text-basic-content": !open,
                            })}
                        >
                            {title}
                        </Heading>
                        <motion.i
                            className={cls("ri-arrow-up-s-line inline-block text-[1.5rem] leading-none", {
                                "!text-secondary": open,
                                "!text-basic-content": !open,
                            })}
                            animate={{
                                rotate: open ? 180 : 0,
                            }}
                            transition={{
                                type: "tween",
                                duration: 0.2,
                            }}
                            layout
                        ></motion.i>
                    </Button>
                </Collapsible.Trigger>
                <AnimatePresence initial={false}>
                    {open && (
                        <Collapsible.Content asChild forceMount>
                            <motion.div
                                initial={{ height: 0 }}
                                animate={{ height }}
                                exit={{ height: 0 }}
                                transition={{ type: "tween" }}
                                className="overflow-hidden p-4"
                            >
                                {children}
                            </motion.div>
                        </Collapsible.Content>
                    )}
                </AnimatePresence>
            </GradientBackground>
        </Collapsible.Root>
    )
}
