import { GradientBorder } from "@components/atoms/gradient"
import * as Form from "@radix-ui/react-form"
import * as Popover from "@radix-ui/react-popover"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import { cls } from "@utils"
import React, { PropsWithChildren } from "react"
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize"
import { Text } from "./typography"

type IErrors = {
    messages?: Form.FormMessageProps[]
}

function Errors(props: IErrors) {
    const { messages } = props

    if (!messages?.length) {
        return null
    }

    return (
        <>
            {messages?.map((message, idx) => {
                const { match, content } = message
                return (
                    <VisuallyHidden.Root key={idx}>
                        <Form.Message match={match} key={idx}>
                            {content}
                        </Form.Message>
                    </VisuallyHidden.Root>
                )
            })}
            <Form.ValidityState>
                {(validity) => {
                    if (validity && !validity.valid) {
                        const matches = Object.entries(validity)
                            .filter((v) => v[1])
                            .map((v) => v[0])

                        return (
                            <Popover.Root>
                                <Popover.Trigger className="leading-none">
                                    <i className="ri-spam-fill text-[1.5rem] text-secondary-accent"></i>
                                </Popover.Trigger>
                                <Popover.Portal>
                                    <Popover.Content
                                        side="top"
                                        sideOffset={10}
                                        align="end"
                                        className="z-40 rounded-2xl border-2 border-secondary-accent bg-warning/10 px-6 py-2 text-secondary-accent"
                                    >
                                        {/* filter message to show only actual matches */}
                                        {messages
                                            ?.filter(({ match }) => {
                                                return matches.find((m) => {
                                                    const isDirectMatch = m === match
                                                    const isCustomMatch =
                                                        typeof match === "function" && m === "customError"
                                                    return isDirectMatch || isCustomMatch
                                                })
                                            })
                                            .map(({ content }) => {
                                                return (
                                                    <Text variant="body2" key={content}>
                                                        {content}
                                                    </Text>
                                                )
                                            })}
                                    </Popover.Content>
                                </Popover.Portal>
                            </Popover.Root>
                        )
                    }
                }}
            </Form.ValidityState>
        </>
    )
}

export const Radius = {
    small: "rounded-lg",
    big: "rounded-3xl",
    full: "rounded-full",
}

type IBaseInput = PropsWithChildren<{
    name: string
    label?: string
    messages?: Form.FormMessageProps[]
    radius?: keyof typeof Radius
}>

function BaseInput(props: IBaseInput) {
    const { name, label, messages, radius = "small", children } = props

    return (
        <Form.Field name={name} className="grid gap-2.5">
            {label && (
                <div className="flex h-[1.5rem] items-end justify-between">
                    <Form.Label className="font-label text-label text-black-white">{label}</Form.Label>
                    <Errors messages={messages} />
                </div>
            )}
            <GradientBorder variant="dark" radius={radius}>
                <Form.Control
                    asChild
                    className={cls(
                        `gradient-border-light bg-basic-accent text-basic-content relative py-3 text-body2 focus-visible:outline-none
                        focus-visible:outline-offset-0 data-[invalid]:text-secondary-accent data-[invalid]:outline data-[invalid]:outline-1
                        data-[invalid]:outline-secondary-accent/30`,
                        Radius[radius],
                        {
                            "px-4": radius === "small",
                            "px-6": radius !== "small",
                        }
                    )}
                >
                    {children}
                </Form.Control>
            </GradientBorder>
        </Form.Field>
    )
}

export type IInput = IBaseInput & React.ComponentProps<"input">

export function Input(props: IInput) {
    const { name, label, messages, radius = "small", ...rest } = props

    return (
        <BaseInput name={name} label={label} messages={messages} radius={radius}>
            <input {...rest} autoCapitalize="off" autoCorrect="off" />
        </BaseInput>
    )
}

export type ITextAreaInput = IBaseInput & TextareaAutosizeProps

export function TextAreaInput(props: ITextAreaInput) {
    const { name, label, messages, radius = "small", ...rest } = props

    return (
        <BaseInput name={name} label={label} messages={messages} radius={radius}>
            <TextareaAutosize {...rest} autoCapitalize="off" autoCorrect="off" />
        </BaseInput>
    )
}
