export const ACCOUNT_TYPES = {
    Private: {
        id: 1,
        type: "Private",
        pricesIncludeVAT: true,
    },
    Enterprise: {
        id: 2,
        type: "Enterprise",
        pricesIncludeVAT: false,
    },
} as const

// Define types from the constants
export type AccountTypeId = (typeof ACCOUNT_TYPES)[keyof typeof ACCOUNT_TYPES]["id"]
export type AccountTypeValue = (typeof ACCOUNT_TYPES)[keyof typeof ACCOUNT_TYPES]["type"]
