import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { IUserSubscription, UserSubscriptionSchema } from "@energuide/shared-payments"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export function useUserSubscription() {
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["user-subscription", authHeader],
        queryFn: async (): Promise<IUserSubscription | null> => {
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/user-subscription/active`, {
                    method: "GET",
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response || !response.data) {
                return null
            }

            const { data } = response
            const parsed = handleParse(() => UserSubscriptionSchema.parse(data))
            return parsed
        },
        enabled: !!authHeader,
    })
}
