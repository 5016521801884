import React from "react"
import { toast } from "sonner"

type IUseFilePicker = {
    maxFiles: number
}

export function useFilePicker(params: IUseFilePicker) {
    const { maxFiles } = params

    const [files, setFiles] = React.useState<File[]>([])
    const ref = React.useRef<HTMLInputElement | null>(null);

    const setRef: React.RefCallback<HTMLInputElement> = React.useCallback(input => {

        if (!input) {
            return
        }

        ref.current = input;
        input.addEventListener('change', () => {
            const newFiles = input.files || [];
            if (files.length + newFiles.length > maxFiles) {
                toast.error(`Sie können maximal ${maxFiles} Dateien auswählen`)
                return
            }

            for (const file of newFiles) {
                console.log(file.type)
            }

            setFiles([...files, ...newFiles])
        })
    }, [files, maxFiles])

    const pickFiles = async () => {
        ref.current?.click()
    }

    const reset = () => {
        setFiles([])
    }

    const remove = (file: File) => {
        setFiles(files.filter((f) => f !== file))
    }

    return {
        ref,
        setRef,
        pickFiles,
        reset,
        remove,
        files,
    }
}
