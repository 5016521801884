import { z } from "zod"

// Notes: When we fetch the Ref is always either null | undefined or the actual object
// But when we create the Ref the value is a string or number.
// What is the right way to distinguish between the two and provide the correct type?
export const Ref = <T extends z.ZodTypeAny>(schema?: T) => {
    const objectSchema = schema ?? z.any()

    // The object schema check first, then fallback to null, undefined, string, number
    return z.union([
        objectSchema,
        z.null(),
        z.undefined(),
        z.string(),
        z.number(),
    ])
}
