import { Button } from "@components/atoms/buttons"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { Input } from "@components/atoms/input.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { Footer } from "@layouts/auth.tsx"
import * as Form from "@radix-ui/react-form"
import { motion } from "framer-motion"
import React, { forwardRef, useImperativeHandle } from "react"
import { Link } from "react-router-dom"
import { toast } from "sonner"
import { z } from "zod"

interface Props {
    onNext: (data: { email: string; password: string }) => void
}

export const Register = forwardRef((props: Props, ref) => {
    const { onNext } = props
    const formRef = React.useRef<HTMLFormElement | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)

    useTitlebar({
        title: "",
        mode: "back",
        backTo: "/auth/register-selection",
        showContextMenu: false,
    })

    useImperativeHandle(ref, () => ({
        resetForm() {
            formRef.current?.reset()
        },
    }))

    const extractData: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        if (!formRef.current) {
            return
        }

        try {
            setLoading(true)
            const formData = new FormData(formRef.current)
            const registerData = Object.fromEntries(formData)

            const email = registerData.email
            if (!email && typeof email !== "string") {
                toast.warning("E-Mail Adresse benötigt")
                return
            }
            const password = registerData.password
            if (!password && typeof password !== "string") {
                toast.warning("Passwort benötigt")
                return
            }

            onNext({ email: z.string().parse(email), password: z.string().parse(password) })
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <EnerGuideDark
                className="absolute inset-0 z-[-1] justify-self-center overflow-hidden"
                energuideClassName="absolute left-[25%] top-[25%] z-[-1] aspect-square w-[150%]"
                layoutId="energuide-dark"
            />
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-12%] lg:top-[-22%] z-[-1] aspect-square justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[22rem] 2xl:w-[40rem] "
                layoutId="energuide"
            />

            <div className="mx-auto min-h-full w-full max-w-screen-sm content-end lg:content-center">
                <div className="bg-background/20 grid shrink-0 gap-12 rounded-lg px-8 pb-8 pt-12 backdrop-blur-2xl">
                    <motion.div className="grid gap-4" layoutId="register-title">
                        <Heading level="h1">Registrieren</Heading>
                        <Text variant="body1" className="text-basic-content-medium">
                            Nur noch eben einen Account anlegen, dann kann es direkt losgehen.
                        </Text>
                    </motion.div>

                    <Form.Root onSubmit={extractData} ref={formRef} className="grid gap-12">
                        <motion.div
                            transition={{
                                type: "tween",
                                ease: "circInOut",
                            }}
                            style={{ originX: 0.5, originY: 1 }}
                            initial={{
                                scaleY: 0.75,
                                transform: "translateY(25%)",
                                opacity: 0,
                            }}
                            animate={{
                                scaleY: 1,
                                transform: "translateY(0%)",
                                opacity: 1,
                            }}
                            className="grid gap-6"
                        >
                            <Input
                                name="email"
                                label="E-Mail"
                                type="text"
                                required
                                messages={[
                                    {
                                        content: "E-Mail Adresse benötigt",
                                        match: "valueMissing",
                                    },
                                    {
                                        content: "E-Mail Adresse ungültig",
                                        match: "typeMismatch",
                                    },
                                ]}
                                data-testid="register-email-input"
                            />
                            <Input
                                name="password"
                                label="Passwort"
                                type="password"
                                required
                                minLength={6}
                                messages={[
                                    {
                                        content: "Mindestens 6 Zeichen benötigt",
                                        match: "tooShort",
                                    },
                                    {
                                        content: "Passwort benötigt",
                                        match: "valueMissing",
                                    },
                                ]}
                                data-testid="register-password-input"
                            />
                        </motion.div>
                        <Form.Submit asChild>
                            <Button variant="primary" loading={loading} data-testid="register-submit-btn">
                                Registrieren
                            </Button>
                        </Form.Submit>
                    </Form.Root>
                </div>

                <Footer>
                    <Text variant="small" className="text-basic-content-medium">
                        Du hast bereits einen Account?
                    </Text>
                    <Link to="/auth/login-selection" className="text-black-white underline">
                        Login
                    </Link>
                </Footer>
            </div>
        </>
    )
})
