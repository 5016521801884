// sort-imports-ignore
import "./css/index.css"

import { queryClient } from "@api/apiConfig.ts"
import { ErrorFallback } from "@components/atoms/error-fallback.tsx"
import OverlayProvider from "@components/molecules/overlay/overlay-context.tsx"
import config from "@config"
import * as SentryReact from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import React from "react"
import ReactDOM from "react-dom/client"
import { ErrorBoundary } from "react-error-boundary"
import { HelmetProvider } from "react-helmet-async"
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import "remixicon/fonts/remixicon.css"
import { App } from "./App.tsx"

const initializeSentry = async () => {
    if (config.VITE_APP_ENV !== "local") {
        SentryReact.init({
            dsn: config.VITE_SENTRY_DSN,
            tunnel: "/tunnel",
            integrations: [
                SentryReact.browserTracingIntegration(),
                SentryReact.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                    maxReplayDuration: 60000,
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1.0,
        })
    }
}

void initializeSentry()

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="*"
            element={
                <ErrorBoundary fallback={<ErrorFallback />}>
                    <App />
                </ErrorBoundary>
            }
        />
    )
)

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <SentryReact.ErrorBoundary fallback={<ErrorFallback />}>
            <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                    <OverlayProvider>
                        <RouterProvider router={router} />
                    </OverlayProvider>
                </QueryClientProvider>
            </HelmetProvider>
        </SentryReact.ErrorBoundary>
    </React.StrictMode>
)
