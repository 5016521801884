import { ISO8601DateSchema, Ref } from "@energuide/shared-common"
import { z } from "zod"
import { ProductSchema } from "../product"

const OrderSchema = z.object({
    id: z.number(),
    stripeSessionId: z.string(),
    isPaid: z.boolean(),
    isCancelled: z.boolean(),
    error: z.string().nullable(),
    stripePaymentIntent: z.string().nullable().optional(),
    userRef: z.any(), // Ref(UserSchema),
    totalPrice: z.number(),
    orderDate: ISO8601DateSchema,
    stripeSubscriptionId: z.string().nullable().optional(),
})

type IOrder = z.infer<typeof OrderSchema>

const OrderItemSchema = z.object({
    id: z.number(),
    orderRef: Ref(OrderSchema),
    productRef: Ref(ProductSchema),
    quantity: z.number(),
    price: z.number(),
})

type IOrderItem = z.infer<typeof OrderItemSchema>

export * from "./post"
export { OrderSchema, OrderItemSchema }
export type { IOrder, IOrderItem }
