import { Heading, Text } from "@components/atoms/typography"
import { ContentOffset } from "@components/molecules/content-offset"
import { DataBlock } from "@components/organisms/data-block"
import { useProjectIdParam } from "@hooks/useProjectIdParam"
import { useTitlebar } from "../hooks/useTitlebar"

export function DataOverview() {
    const projectId = useProjectIdParam()

    useTitlebar({
        title: "",
        mode: "back",
        backTo: `/authenticated/project-overview/${projectId ?? ""}`,
        showContextMenu: true,
        contextMenuProps: {
            showDataExport: true,
        },
        projectId,
    })

    return (
        <ContentOffset offsetAppbar={true}>
            <section className="mx-auto grid w-full max-w-screen-md content-start gap-6 p-6">
                <section className="grid gap-4">
                    <div className="grid gap-2">
                        <div className="grid gap-1">
                            <Text variant="small" className="text-basic-content">
                                Verbrauchsausweis
                            </Text>
                            <Heading level="h2">Datenübersicht</Heading>
                        </div>
                        <Text variant="body2" className=" text-basic-content-medium">
                            Hier kannst du die bisher eingegebenen Daten die für den Verbrauchsausweis relevant sind
                            einsehen und editieren.
                        </Text>
                    </div>
                </section>

                <DataBlock
                    defaultOpen
                    title="Kundendaten"
                    sections={[
                        {
                            heading: "Person",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                        {
                            entries: [
                                {
                                    label: "Rechtsform",
                                    name: "legal-form",
                                    value: "GmbH",
                                },
                            ],
                        },
                        {
                            heading: "Kundenadresse",
                            entries: [
                                {
                                    label: "Straße",
                                    name: "street",
                                    value: "Musterstraße",
                                },
                                {
                                    label: "Hausnummer",
                                    name: "house-number",
                                    value: "2a",
                                },
                                {
                                    label: "PLZ",
                                    name: "zip-code",
                                    value: "01099",
                                },
                                {
                                    label: "Ort",
                                    name: "city",
                                    value: "Dresden",
                                },
                            ],
                        },
                        {
                            heading: "Objektadresse",
                            entries: [
                                {
                                    label: "Straße",
                                    name: "street",
                                    value: "Musterstraße",
                                },
                                {
                                    label: "Hausnummer",
                                    name: "house-number",
                                    value: "3c",
                                },
                                {
                                    label: "PLZ",
                                    name: "zip-code",
                                    value: "01099",
                                },
                                {
                                    label: "Ort",
                                    name: "city",
                                    value: "Dresden",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Allgmeine Gebäudedaten"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Allgmeine technische Daten"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Technische Daten Wärmeerzeuger"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Technische Daten Wärmeabgabe"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Technische Daten Trinkwarmwasser"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Technische Daten Lüftung"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Technische Daten Kühlung"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Thermische Gebäudehülle"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
                <DataBlock
                    title="Technische Daten PV / Solar"
                    sections={[
                        {
                            heading: "Testing",
                            entries: [
                                {
                                    label: "Anrede",
                                    name: "salutation",
                                    value: "Frau",
                                },
                                {
                                    label: "Vorname",
                                    name: "first-name",
                                    value: "Michaela",
                                },
                                {
                                    label: "Nachname",
                                    name: "last-name",
                                    value: "Muster",
                                },
                            ],
                        },
                    ]}
                />
            </section>
        </ContentOffset>
    )
}
