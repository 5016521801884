import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useUserKey, UserSchema } from "@energuide/shared-user"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useAppState } from "@hooks/useState"
import { useQuery } from "@tanstack/react-query"

type IUseUser = {
    enabled?: boolean
}

export function useUser(params?: IUseUser) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()
    const setUser = useAppState((state) => state.setUser)

    return useQuery({
        queryKey: useUserKey,
        queryFn: async () => {
            const data = await handleFetch(() =>
                fetch(`${apiUrl}/users/me?populate=avatar&populate=accountTypeRef&populate=role`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const user = handleParse(() => {
                const validatedUser = UserSchema.parse(data)
                setUser(validatedUser)
                return validatedUser
            })

            return user
        },
        enabled: enabled && !!authHeader,
    })
}
