import { handleFetch, apiUrl } from "@api/apiConfig"
import { AccountTypeIdSchema } from "@energuide/shared-payments"
import { z } from "zod"

export const RegisterSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6),
    type: AccountTypeIdSchema,
})

export type RegisterSchemaType = z.infer<typeof RegisterSchema>

export async function register(params: RegisterSchemaType) {
    const { email, password, type } = params
    await handleFetch(() =>
        fetch(`${apiUrl}/auth/local/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                username: email,
                password,
                account_type: type,
            }),
        })
    )
}
