import { z } from "zod"

const Config = z.object({
    VITE_APP_ENV: z.enum(["local", "production", "staging"]),
    VITE_SENTRY_DSN: z.string(),
    VITE_STRIPE_PUBLIC_KEY: z.string(),
    VITE_BACKEND_URL: z.string(),
    VITE_FRONTEND_URL: z.string(),
    VITE_MAPBOX_ACCESS_TOKEN: z.string(),
})

type ConfigType = z.infer<typeof Config>

let config: ConfigType

try {
    config = Config.parse(import.meta.env)
} catch (error: any) {
    const root = document.getElementById("root")
    if (root) {
        root.innerHTML = `
            <div class="w-full min-h-dvh grid place-content-center gap-2 font-text text-body1 text-basic-content-medium">
                <h1 class="font-heading text-heading2 text-black-white">Config Error</h1>
                <p>One or more environment variables are invalid</p>
                <p>Error:</p>
                <p class="whitespace-pre">${JSON.stringify(error, null, 2)}</p>
            </div>
        `
    }

    throw error
}

export default config
