import { useAppState } from "@hooks/useState"
import { cls } from "@utils"
import { PropsWithChildren } from "react"

type IContentOffset = PropsWithChildren<{
    className?: string
    offsetAppbar: boolean
}>

export function ContentOffset(props: IContentOffset) {
    const { children, className, offsetAppbar } = props
    const appbarSize = useAppState((state) => state.appbarSize)

    return (
        <div
            className={cls(className)}
            style={{
                paddingTop: `calc(${offsetAppbar ? appbarSize[1] : 0}px)`,
            }}
        >
            {children}
        </div>
    )
}
